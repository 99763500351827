<template>
  <div
    id="contents"
    class="machine_management"
    :class="{ management: managementMode }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li class="active">
            <a href="#">조회 및 관리</a>
          </li>
        </ul>
      </div>
      <MachineManagementForm />
      <!-- <MachineForm /> -->
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import { mapGetters, mapMutations } from 'vuex';
import MachineManagementForm from '@/views/forms/Machine/MachineManagement/MachineManagementForm';
// import MachineForm from '@/views/forms/Machine/MachineManagement/MachineForm.vue';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    MachineManagementForm,
    // MachineForm,
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromMachineManagementPage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToMachineManagementPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitMachineManagementPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style></style>
